/* Helevetica Neue Font Imports */
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HeleveticaNeue/HelveticaNeue-Bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HeleveticaNeue/HelveticaNeue-Light.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HeleveticaNeue/HelveticaNeue-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HeleveticaNeue/HelveticaNeue-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HeleveticaNeue/HelveticaNeue-RegularItalic.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HeleveticaNeue/HelveticaNeue-Thin.otf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HeleveticaNeue/HelveticaNeue-Ultralight.otf');
  font-weight: 200;
  font-style: normal;
}

/* Recoleta Fonts Imports*/
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Black.ttf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Bold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Ultra-Light.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('./assets/fonts/gt-walsheim/GT-Walsheim-Pro-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

/*  */
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Black.ttf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-UltraLight.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

body {
  /* max-width: 1382px; */
  margin: 0 auto;
  position: relative;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', sans-serif;
}

button {
  text-transform: uppercase;
  cursor: pointer;
}

.bzo-container {
  padding-left: 9.375em;
  padding-right: 7.5em;
  padding-bottom: 70px;
  padding-top: 70px;
}

@media screen and (max-width: 1300px) {
  @media screen and (max-width: 1140px) {
    .bzo-container {
      padding-left: 9.375em;
      padding-right: 4.5em;
    }
  }
  @media screen and (max-width: 860px) {
    .bzo-container {
      padding-left: 7.375em;
      padding-right: 5.5em;
    }
  }
  @media screen and (max-width: 760px) {
    .bzo-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  @media screen and (max-width: 280px) {
    .bzo-container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

/* Loader styles */

.loader {
  width: 50px;
  height: 50px;
  background: rgba(238, 238, 238, 0.4);
  border-radius: 50%;
  position: relative;
  animation: skLinRotate 1s ease-in-out infinite alternate;
}
.loader:after {
  content: '';
  position: absolute;
  inset: 5px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: rgb(85, 85, 255);
}

@keyframes skLinRotate {
  95%,
  100% {
    transform: rotate(840deg);
  }
}

/* Toast styles */
#error-toast {
  background-color: #d7263d;
  font-size: 13px;
  padding-left: 20px;
  font-weight: 500;
  color: white;
  border: 2px solid white;
  font-family: 'Gilroy';
  right: 0px;
}

#error-toast svg {
  fill: white;
}

#success-toast {
  background-color: #5555ff;
  font-size: 13px;
  padding-left: 20px;
  font-weight: 500;
  color: white;
  border: 2px solid white;
  font-family: 'Gilroy';
  right: 0px;
}

#success-toast svg {
  fill: white;
}

#error-toast [role='progressbar'],
#success-toast [role='progressbar'] {
  background: white;
}

input[type='radio'] {
  display: none;
}

hr {
  max-width: 70%;
  margin-top: 40px;
  margin-bottom: 40px;
  opacity: 0.6;
}

/* Input file */
.custom-file-input {
  color: transparent;
  font-family: 'GT Walsheim Pro';
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Browse';
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 500;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* Link styles */
a,
a:hover {
  text-decoration: none;
  color: #5555ff;
}

.underline {
  position: relative;
  width: fit-content;
}

.underline::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #5555ff;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (hover: hover) and (pointer: fine) {
  .underline:hover::before {
    left: 0;
    right: auto;
    width: 100%;
  }
}
