.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputContainer * {
  font-family: 'GT Walsheim Pro' !important;
}

.label {
  width: fit-content;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 15px;
}

.input {
  border: none;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  height: 100px;
  background: #fff;
  width: 100%;
  padding: 0.375rem 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  max-width: 500px;
}

.input:focus-visible,
.input:focus,
.input:active {
  outline-color: #5555ff;
}

.input::placeholder {
  font-size: 15px;
  color: #6c757d;
  opacity: 0.4;
  font-family: 'Gilroy';
}

.asterisk {
  color: #d7263d;
}

.optionalMsg {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.4;
}

.inputContainer .error {
  margin-top: 0px;
  color: #d7263d;
  font-weight: 500;
  margin-top: 6px;
  font-size: 14px;
}

@media screen and (max-width: 620px) {
  .input {
    min-width: unset !important;
    width: 100% !important;
  }
}
