#scroll-to-top {
  background-color: #55e3ff;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 10px;
  left: 10px;
  padding: 15px;
  border-radius: 20px;
  z-index: 10000;
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

#scroll-to-top:hover {
  transform: translateY(-5px);
}

#bzo-form-frame {
  padding: 20px;
  padding-top: 100px;
  border: 2px solid #5555ff;
  border-radius: 20px;
  width: 90%;
  display: flex;
  margin: 0 auto;
}

.bzo-container.bzo-main-hero-section {
  padding-top: 250px;
  padding-bottom: 100px;
  position: relative;
  background: url('../../assets/hero-image.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
}

.bzo-hero-button a,
.bzo-hero-button {
  text-decoration: none;
  color: #5555ff;
}

.bzo-hero-button {
  text-decoration: none;
  font-weight: 500;
  background-color: #fff;
  border: 2px solid #ffffff;
  border-radius: 4px;
  padding: 9px 59px;
  margin-top: 37px;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  letter-spacing: 2.7px;
}

.bzo-hero-button:hover {
  background-color: transparent;
}
.bzo-hero-button:hover > a,
.bzo-hero-button:hover {
  color: white;
}

.main-hero-content h1,
.bzo-main-program-description-section h1 {
  font-weight: 900;
  font-size: 77px;
  color: #ededfa;
}

.main-hero-content h1 span,
.bzo-main-program-description-section h1 span {
  display: block;
}

.main-hero-content h1 span:nth-of-type(2),
.bzo-main-program-description-section h1 span:nth-of-type(2) {
  color: #55e3ff;
}
.main-hero-content h1 span:nth-of-type(3) {
  color: #5555ff;
}

.main-hero-content p {
  max-width: 521px;
  margin-top: 37px;
  font-size: 19px;
  line-height: 26px;
  color: #ffffff;
}

.bzo-main-program-description-section {
  padding-top: 97px;
  padding-bottom: 97px;
  background-color: #5555ff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
}

.bzo-main-program-description-section p {
  max-width: 529px;
  width: 80%;
  font-size: 1.125rem;
  line-height: 23px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-right: 50px;
  padding: 50px 0px;
}

/* How it works */
.bzo-how-it-works {
  padding: 80px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bzo-how-it-works h2 {
  width: 80%;
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 1px;
  color: #0b0a0a;
  margin-bottom: 100px;
}

.compressed-steps-arrow {
  display: none;
}

.how-it-works--steps {
  display: flex;
  flex-wrap: wrap;
}

.how-it-works--steps__container {
  margin-bottom: 50px;
  position: relative;
}

.how-it-works--steps__container > img {
  top: 37%;
  right: 0%;
  transform: translateY(-50%);
  position: absolute;
}

.how-it-works--steps .step {
  height: 228px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 20px;
}
.how-it-works--steps .step span {
  display: block;
  width: 100%;
  position: relative;
  font-size: 54px;
  text-align: center;
  color: #5555ff;
}
.how-it-works--steps .step h3 {
  text-align: center;
  padding-top: 50px;
  font-size: 24px;
  color: #0b0a0a;
  margin-bottom: 5px;
}
.how-it-works--steps .step p {
  font-size: 16px;
  color: #0b0a0a;
  width: 100%;
  text-align: center;
}
.how-it-works--steps .step span img.uncompressed-steps-arrow {
  top: 0px;
  position: absolute;
  right: -50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.bzo-section-introduction {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.bzo-section-introduction img {
  width: 100%;
}

.bzo-section-introduction h1 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 67px;
  line-height: 79px;
  text-transform: uppercase;
  color: #ffffff;
}

.bzo-section-introduction.FAQ h1 {
  text-transform: none;
}

@media screen and (max-width: 1160px) {
  .how-it-works--steps.section-2 {
    flex-direction: column-reverse;
  }

  .how-it-works--steps {
    flex-direction: column;
  }
  .how-it-works--steps__container > img {
    display: none;
  }
  .how-it-works--steps .step {
    border-radius: 5px;
    height: 228px;
    transition: all 500ms ease-in-out;
  }

  .how-it-works--steps .step:hover {
    transform: scale(1.05);
  }
  .how-it-works--steps .step span img {
    top: 36%;
    right: -50px;
    transform: translateY(-50%);
    position: absolute;
  }
  img.uncompressed-steps-arrow {
    display: none;
  }
  .compressed-steps-arrow {
    display: inherit;
  }

  .how-it-works--steps .step.step_3 img {
    top: 16px;
  }

  .how-it-works--steps .step {
    width: 280px;
  }

  @media screen and (max-width: 415px) {
    .bzo-how-it-works {
      padding: 30px 10px;
    }
    .bzo-how-it-works h2 {
      margin-bottom: 50px;
    }
    .compressed-steps-arrow {
      display: none;
    }
    .how-it-works--steps .step {
      margin: 0 auto;
      margin-top: 20px;
      width: 90%;
    }
  }
}

/* What you'll learn */

.bzo-what-to-learn {
  padding: 91px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.what-to-learn--logos {
  max-width: 569.98px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 100px;
}

.bzo-hero-button.blue-variant a,
.bzo-hero-button.blue-variant {
  text-decoration: none;
  color: white;
}
.bzo-hero-button.blue-variant {
  background: #5555ff;
  border-radius: 4px;
}
.bzo-hero-button.blue-variant:hover {
  background: transparent;
  border: 2px solid #5555ff;
}
.bzo-hero-button.blue-variant:hover > a,
.bzo-hero-button.blue-variant:hover {
  color: #5555ff;
}

.bzo-hero-button:disabled,
.bzo-hero-button:hover:disabled {
  color: #777;
  background-color: #99999959;
  border: 2px solid #999999;
  font-weight: 600;
  cursor: not-allowed;
}

/* Requirements section */

.bzo-bezao-requirements {
  padding-top: 90px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1382px;
  margin: 0 auto;
}

.requirement-item-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.requirement-item {
  width: 350px;
  padding: 32px;
}

.requirement-item p {
  font-family: Manrope;
  max-width: 250px;
  margin-top: 40px;
  font-weight: 600;
  font-size: 18px;
}

/* FAQ section */

.bzo-bezao-FAQ {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.bzo-bezao-FAQ > h1 {
  padding: 0px;
  font-weight: 900;
  font-size: 50px;
  color: #5555ff;
  margin-bottom: 60px;
}

.accordion {
  width: 100%;
  margin: 0 auto;
}
.card {
  border: 1px solid rgba(85, 227, 255, 0.63);
  border-left: none;
  border-right: none;
  padding-top: 10px;
}

.card:first-of-type {
  border: none;
}
.collapse.show {
  border: none;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  color: #5555ff;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  padding-left: 0px;
  border: none;
}
.card-header h1 {
  margin-bottom: 0px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.card-header h1 svg {
  width: 44px;
  margin-left: 20px;
}

.card-body {
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 10%;
}

/* Facilitator section */

.bzo-facilitators {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.bzo-facilitators p {
  margin: 0px;
}
.facilitators-logo-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.facilitators-logo-containers img {
  margin: 30px;
}

/* Footer */

.bzo-landing-footer {
  height: 289px;
  display: flex;
  background-color: #5555ff;
}

.bzo-landing-footer img {
  width: fit-content;
  height: fit-content;
}

@media screen and (max-width: 1300px) {
  @media screen and (max-width: 1190px) {
    .bzo-main-program-description-section {
      justify-content: center;
    }
    .bzo-main-program-description-section.bzo-container {
      padding-left: 4em;
      padding-right: 4em;
    }
    .bzo-main-program-description-section p {
      margin: 0 auto;
      padding: 0px;
    }
    .bzo-main-program-description-section h1 {
      margin-top: 50px;
      width: 100%;
    }

    @media screen and (max-width: 998px) {
      .bzo-main-program-description-section h1,
      .bzo-main-program-description-section p {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 1140px) {
    .nav-link-item {
      margin: 0px 0.5em;
    }
  }

  @media screen and (max-width: 921px) {
    .requirement-item {
      width: fit-content;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .requirement-item p {
      text-align: center;
    }
  }
  @media screen and (max-width: 900px) {
    .bzo-section-introduction h1 {
      font-size: 43px;
      line-height: normal;
      text-align: center;
      width: 90%;
    }

    .bzo-bezao-FAQ > h1 {
      font-size: 4vw;
    }
  }
  @media screen and (max-width: 760px) {
    .bzo-container.bzo-main-hero-section {
      padding-top: 150px;
      background-position: 30%, 74%;
    }
    .main-hero-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .main-hero-content h1 {
      font-size: 56px;
    }
    .main-hero-content p {
      max-width: 300px;
    }
    .main-hero-content h1,
    .main-hero-content p {
      text-align: center;
    }
  }

  @media screen and (max-width: 700px) {
    .card-header {
      padding-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .card-header h1 {
      width: 80%;
      margin-bottom: 0px;
      /* display: none; */
    }
  }
  @media screen and (max-width: 561px) {
    .what-to-learn--logos {
      margin-bottom: 27px;
    }
    .what-to-learn--logos img {
      margin: 30px;
    }
  }
  @media screen and (max-width: 415px) {
    .requirement-item-container {
      margin-bottom: 0px;
    }

    .requirement-item p {
      text-align: center;
    }
    .bzo-container.bzo-main-hero-section {
      padding-top: 150px;
    }
    .bzo-hero-button,
    .bzo-hero-button.blue-variant {
      width: 200px;
      font-size: 0.8rem;
      padding: 9px 33px;
    }
    .main-hero-content h1,
    .bzo-main-program-description-section h1 {
      text-align: center;
      font-size: 33px;
    }
    .bzo-main-program-description-section.bzo-container {
      padding-left: 30px;
      padding-right: 30px;
    }
    .bzo-main-program-description-section p {
      font-size: 16px;
      letter-spacing: normal;
      padding: 0px 0px;
      line-height: normal;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
    }
    .bzo-section-introduction h1 {
      font-size: 23px;
      line-height: normal;
      width: 90%;
    }

    .bzo-bezao-FAQ > h1 {
      text-align: center;
      font-weight: 600;
      font-size: 27px;
      margin-bottom: 29px;
    }

    .card-header h1 {
      font-size: 18px;
    }

    .bzo-bezao-FAQ {
      margin-bottom: 0px;
    }

    .facilitators-logo-containers {
      margin-top: 30px;
      justify-content: center;
    }
    .facilitators-logo-containers img {
      margin: 20px 0px;
    }
  }
}
