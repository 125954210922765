.timeContainer {
  display: flex;
  margin-bottom: 30px;
}

.timeContainer * {
  font-family: 'Gilroy', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.timeContainer .numbers,
.timeContainer .type {
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}
.timeContainer .type {
  font-weight: 500;
}

.timeContainer .numbers {
  font-size: 38px;
  font-weight: 700;
}

.days,
.hours,
.minutes,
.seconds {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 160px;
  height: 130px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgb(255 255 255 / 15%), 0 2px 47px -12px rgb(0 0 0 / 25%);
  color: white;
}

.days {
  background-color: #5555ff;
}

.hours {
  background-color: #d7263d;
  margin-left: 5px;
}

.minutes {
  background-color: #02182b;
  margin-left: 5px;
}

.seconds {
  margin-left: 5px;
  background-color: #44bba4;
}
.numbers {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5em;
}

.expiredNotice svg {
  width: 280px;
  height: 280px;
}

@media screen and (max-width: 1100px) {
  .days,
  .hours,
  .minutes,
  .seconds {
    padding: 10px;
    width: 138px;
    height: 120px;
  }
}

@media screen and (max-width: 540px) {
  .days,
  .hours,
  .minutes,
  .seconds {
    height: fit-content;
  }

  .timeContainer .numbers {
    font-size: 32px;
    line-height: 1.2;
  }

  .timeContainer .type {
    font-size: 14px;
  }
}
@media screen and (max-width: 450px) {
  .days,
  .hours,
  .minutes,
  .seconds {
    padding: 15px;
    width: 138px;
  }
  .timeContainer .numbers {
    font-size: 28px;
    line-height: 1.2;
  }

  .timeContainer .type {
    font-size: 13px;
  }
}
