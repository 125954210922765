.container {
  width: 100vw;
  height: 100vh;
  background-color: #f6f7fd;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 200px;
}

.container hr {
  max-width: 100%;
}

.container h1,
.container h2,
.container h3,
.container h4,
.container h5,
.container h6 {
  font-family: 'Gilroy', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana,
    sans-serif;
}

.container * {
  font-family: Manrope;
}

.checkbox_wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}

.header > svg {
  max-width: 300px;
  max-height: 300px;
}

.header h4 {
  margin-top: 20px;
  font-family: 'GT Walsheim Pro';
}

.nav {
  position: fixed;
  background-color: #5555ff;
  height: 80px;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  padding-left: 150px;
  z-index: 2;
}

.nav img {
  width: 100px;
  cursor: pointer;
}

.registrationContainer {
  max-width: 1200px;
  padding-right: 0px;
  padding-left: 150px;
  padding-top: 130px;
  width: 100%;
  display: flex;
}

/* For form submit */
.registrationContainer .button {
  margin-top: 0px;
}

.registrationContainer .button.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.registrationContainer .button.loading span {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.registrationContainer .button.loading:not(:disabled):hover span {
  background-color: #5555ff;
}

.registrationContainer .button.loading:not(:disabled):hover span::after {
  background-color: #fff;
}
.registrationContainer .button.loading:disabled,
.registrationContainer .button.loading:disabled:hover {
  color: #777;
}

.registrationContainer .button.loading:disabled span::after,
.registrationContainer .button.loading:disabled:hover span::after {
  background-color: #999999;
  border-top-color: #f6f7fd;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.form {
  width: 100%;
}

.submitbg {
  top: 0px;
  right: 0px;
  position: fixed;
  background-image: url('../../assets/icon-svgs/apply_image.jpeg');
  background-position: 50%;
  height: 100%;
  width: 20%;
  min-width: 200px;
  display: flex;
  background-size: cover;
}

/* Field group */

.field_group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.field_group.column {
  flex-direction: column;
}

.field_group_header {
  font-family: 'GT Walsheim Pro' !important;
  width: fit-content;
  font-weight: 500;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 8px;
}
.asterisk {
  color: #d7263d;
}

/* Content */
.content {
  margin-bottom: 30px;
}

.content h2,
.loadingContainer h2 {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 2.5em;
  font-family: 'GT Walsheim Pro';
  letter-spacing: -0.017em;
}

.content h2 span,
.loadingContainer h2 span {
  font-weight: 600;
  color: #5555ff;
}

.content h4 {
  margin-top: 20px;
  margin-bottom: 15px;
  -webkit-text-stroke: 1px #000;
  color: #2f2d2e;
  font-size: 27px;
}

.content > p,
.form p,
.item,
.updateText {
  font-weight: 300;
  font-size: 16px;
  max-width: 700px;
  font-family: 'GT Walsheim Pro';
}

.updateText {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
}

/* Text Input */
.textInput {
  width: fit-content;
  margin-right: 30px;
}

.textInput p {
  margin-bottom: 0px;
}

.textInput label {
  margin-top: 0px;
}

.textInput input,
.textInput textarea {
  min-width: 400px;
  width: fit-content;
}

.textInput textarea {
  min-width: 500px;
}

/* Success */
.form .successMsg {
  margin-top: 30px;
  background-color: #44bba4;
  color: white;
  font-weight: 600;
  padding: 10px 30px;
  margin-left: 2px;
  width: fit-content;
  border-radius: 5px;
}

.container .toast {
  font-size: 13px;
  background: #5555ff;
  padding-left: 20px;
  font-weight: 500;
  color: white;
  border: 2px solid white;
  font-family: 'Gilroy', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana;
}

.container .toast button {
  color: white;
}

/* Personal Form */
.form_section fieldset {
  border: none;
}
.form_section fieldset > legend {
  margin-top: 20px;
  margin-bottom: 15px;
  -webkit-text-stroke: 1px #000;
  color: #2f2d2e;
  font-size: 24px;
  font-weight: 600;
}

.errorMsg {
  margin-top: 0px;
  color: #d7263d;
  font-weight: 500;
  margin-top: 6px;
  font-size: 14px;
  margin-bottom: 0px;
  font-family: 'GT Walsheim Pro';
}

/* Radio Buttons */
.gender_form label {
  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 6px;
  width: fit-content;
  font-weight: 400;
}

.gender_form label input + span {
  border: 1px solid #eee;
  font-size: 14px;
  font-family: 'GT Walsheim Pro';
  padding: 12px;
  background-color: white;
}

.gender_form label input:checked + span {
  background-color: #5555ff;
  border: 1px solid;
  color: #fff;
}

.gender_form label input:checked + span:before {
  box-shadow: inset 0 0 0 0.2375em #fff;
  background-color: #5555ff;
}
.gender_form label span {
  min-width: 250px;
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 4px;
  transition: 0.25s ease;
}
.gender_form label span:hover {
  background-color: white;
}
.gender_form label span:before {
  display: flex;
  flex-shrink: 0;
  content: '';
  background-color: #fff;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 0.6em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.225em #5555ff;
}

/* Toast */
.container .toast {
  font-size: 13px;
  background: #5555ff;
  padding-left: 20px;
  font-weight: 500;
  color: white;
  border: 2px solid white;
  font-family: 'Gilroy', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana;
}

.container .toast button {
  color: white;
}

@media screen and (max-width: 1250px) {
  .field_group {
    flex-direction: column;
  }

  .field_group .textInput:not(:first-of-type) {
    margin-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .registrationContainer {
    padding-left: 50px;
    padding-right: 30px;
    padding-top: 100px;
  }

  .nav {
    padding-left: 50px;
  }

  .submitbg {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .gender_form label span {
    max-width: 80%;
  }

  .textInput {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .registrationContainer {
    padding-left: 24px;
    padding-right: 24px;
  }

  .gender_form label span {
    width: 100%;
    max-width: 100%;
  }

  .submitted svg {
    max-width: 280px;
    max-height: 280px;
  }

  .submitted {
    padding-top: 100px;
  }

  .nav {
    padding-left: 24px;
  }
}
