/* SOURCE: https://codepen.io/xirclebox/pen/wvGmjbV */
.checkbox_container {
  width: fit-content;
  font-size: 13px;
  list-style: none;
  padding: 5px;
  margin-bottom: 0px;
  position: relative;
}

.checkbox_container label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

.checkbox_container label {
  padding: 8px 15px;
  cursor: pointer;
  font-family: 'GT Walsheim Pro';
}

.checkbox_container label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: '\f067';
  transition: transform 0.3s ease-in-out;
}

.checkbox_container input[type='checkbox']:checked + label::before {
  content: '\f00c';
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

.checkbox_container input[type='checkbox']:checked + label {
  border: 2px solid white;
  background-color: #5555ff;
  color: #fff;
  transition: all 0.2s;
}

.checkbox_container input[type='checkbox'] {
  display: absolute;
}
.checkbox_container input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}
.checkbox_container input[type='checkbox']:focus + label {
  border: 2px solid #adadad;
}

.checkbox_container input[type='checkbox']:checked:focus + label {
  border: 2px solid #5555ff;
}
