.container {
  width: 100vw;
  height: 100vh;
  background-color: #f6f7fd;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 100px;
}

.container *,
.container h1,
.container h2,
.container h3,
.container h4,
.container h5,
.container h6 {
  font-family: 'Gilroy', 'Lucida Sans', sans-serif;
}

.nav {
  position: fixed;
  background-color: #5555ff;
  height: 80px;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  padding-left: 150px;
  z-index: 2;
  cursor: pointer;
}

.nav img {
  width: 100px;
}

.emphasis {
  font-weight: 600;
}

.formWrapper .note {
  text-transform: uppercase;
  width: fit-content;
  min-width: 80px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  background-color: #fff;
  color: #d7263d;
  margin-bottom: 10px;
  border: 2px solid #d7263d;
  border-radius: 6px;
}

.formWrapper .note.info {
  color: #44bba4;
  border-color: #44bba4;
}

.registrationContainer {
  max-width: 1200px;
  padding-right: 0px;
  padding-left: 150px;
  padding-top: 130px;
  width: 100%;
  display: flex;
}

.registrationContainer.loading,
.registrationContainer.noRecordFound {
  height: 100%;
}

.registrationContainer.noRecordFound .button {
  margin-top: 10px;
  width: fit-content;
}

/* For form submit */

.registrationContainer .button.loading {
  display: flex;
  align-items: center;
}

.registrationContainer .button.loading span {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.registrationContainer .button.loading:not(:disabled):hover span {
  background-color: #5555ff;
}

.registrationContainer .button.loading:not(:disabled):hover span::after {
  background-color: #fff;
}
.registrationContainer .button.loading:disabled,
.registrationContainer .button.loading:disabled:hover {
  color: #777;
}

.registrationContainer .button.loading:disabled span::after,
.registrationContainer .button.loading:disabled:hover span::after {
  background-color: #999999;
  border-top-color: #f6f7fd;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.form {
  width: 100%;
}

.submitbg {
  top: 0px;
  right: 0px;
  position: fixed;
  background-image: url('../../assets/icon-svgs/apply_image.jpeg');
  background-position: 50%;
  height: 100%;
  width: 20%;
  display: flex;
  background-size: cover;
}

/* Content */
.content {
  margin-bottom: 30px;
}

.content h2,
.loadingContainer h2 {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 2.5em;
  font-family: 'GT Walsheim Pro';
  letter-spacing: -0.017em;
}

.content h2 span,
.loadingContainer h2 span {
  font-weight: 600;
  color: #5555ff;
}

.content h4 {
  margin-top: 20px;
  margin-bottom: 15px;
  -webkit-text-stroke: 1px #000;
  color: #2f2d2e;
  font-size: 27px;
}

.content > p,
.form p,
.item,
.updateText {
  font-weight: 300;
  font-size: 16px;
  max-width: 700px;
  font-family: 'GT Walsheim Pro';
}

.updateText {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
}

/* Loading Container */
.loadingContainer,
.emptyContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.loadingContainer h2,
.emptyContainer h2 {
  font-size: 16px;
  margin-top: 25px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 600px;
}

.registrationContainer.noRecordFound .emptyContainer h2 {
  margin-top: 50px;
}

.emptyContainer h2 span {
  color: #5555ff;
  font-weight: 600;
}

.emptyContainer svg {
  width: 280px;
  height: fit-content;
}

/* Text Input */
.textInput label {
  margin-top: 20px;
}

.list {
  list-style: none;
  width: 600px;
  max-width: 90%;
}

.item {
  counter-increment: list;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
}

.item:before {
  position: absolute;
  left: 0px;
  top: 0%;
  content: counter(list);
  width: 30px;
  height: 30px;
  color: #f6f7fd;
  font-weight: 500;
  background: #5555ff linear-gradient(to bottom right, #5555ff 25%, #5555ff);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.headline {
  padding: 0rem 0 0 0;
  margin: 0 0 1rem 0;
}

/* Success */
.form .successMsg {
  margin-top: 30px;
  background-color: #44bba4;
  color: white;
  font-weight: 600;
  padding: 10px 30px;
  margin-left: 2px;
  width: fit-content;
  border-radius: 5px;
}

/* Link container */
.link_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.link_container hr {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 40%;
}

.link_container hr:last-of-type {
  margin-bottom: 40px;
}

.link_container .emphasis {
  font-weight: 300;
  margin-bottom: 8px;
  display: flex;
  text-decoration: underline;
}

.link_container span {
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Gilroy';
  max-width: 700px;
  margin-bottom: 4px;
}

.link_container a {
  font-size: 14px;
  font-weight: 700;
}

/* Expired container */
.submittedNotice {
  display: flex;
  flex-direction: column;
}
.submittedNotice svg {
  width: 280px;
  height: 280px;
}

.submittedNotice button {
  width: fit-content;
  margin-top: 20px;
}

@media screen and (max-width: 1200px) {
  .content > p,
  .form p,
  .item,
  .updateText {
    max-width: 500px;
  }
}

@media screen and (max-width: 900px) {
  .registrationContainer {
    padding-left: 50px;
    padding-right: 30px;
    padding-top: 100px;
  }

  .nav {
    padding-left: 50px;
  }

  .submitbg {
    display: none;
  }

  .registrationContainer hr {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .content h2 span {
    display: flex;
    margin-top: 10px;
  }

  .textInput {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .registrationContainer {
    padding-left: 24px;
    padding-right: 24px;
  }

  .registrationContainer hr {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .content h2 {
    font-size: 1.8em;
    margin-bottom: 0px;
  }

  .content h4 {
    font-size: 22px;
  }

  .submitted svg {
    max-width: 280px;
    max-height: 280px;
  }

  .submitted {
    padding-top: 100px;
  }

  .nav {
    padding-left: 24px;
  }
}
