/* 
 *  Landing Nav Bar Styles
 */

.bzo-landing-nav {
  background-color: inherit;
  position: absolute;
  width: 100%;
  padding: 1.8125em;
  padding-left: 9.375em;
  padding-right: 7.5em;
  z-index: 100;
}

.bzo-nav-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bzo-landing-nav-bar--hamburger {
  height: 30px;
  min-width: 30px;
  display: none;
  cursor: pointer;
}

.bzo-nav-links-container.showHamburger {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 200px;
  background-color: #5555ff;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.ham-button {
  display: none;
}

.bzo-nav-links {
  display: flex;
  align-items: center;
}

.bzo-nav-links-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  margin: 0px;
}

.nav-link-item a,
.bzo-nav-button {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 1.7px;
}
.bzo-nav-button a {
  text-decoration: none;
  color: #ffffff;
}
.bzo-nav-button:hover {
  background: rgba(85, 227, 255, 0.63);
}
.nav-link-item {
  text-align: center;
  width: fit-content;
  line-height: 23px;
  cursor: pointer;
  font-weight: 500;
  margin: 0px 2em;
  text-decoration: none;
}

.bzo-nav-button {
  background-color: transparent;
  padding: 5px 41px;
  border: 2px solid #ffffff;
  border-radius: 4px;
}

/* .nav-link-item:hover {
  border-bottom: 3px solid #fff;
} */

@media screen and (max-width: 1300px) {
  .nav-link-item {
    margin: 0px 1em;
  }

  @media screen and (max-width: 1140px) {
    .nav-link-item {
      margin: 0px 0.5em;
    }

    .bzo-nav-links-container {
      display: none;
    }

    .bzo-nav-links {
      width: 100%;
      justify-content: flex-end;
    }

    .bzo-landing-nav-bar--hamburger {
      display: inherit;
      margin: 0px 30px;
    }
  }
  @media screen and (max-width: 860px) {
    .bzo-landing-nav {
      padding-left: 7.375em;
      padding-right: 5.5em;
    }
  }

  @media screen and (max-width: 670px) {
    .ham-button {
      display: inherit;
    }
    .hide-button {
      display: none;
    }
  }
  @media screen and (max-width: 540px) {
    .bzo-landing-nav {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  @media screen and (min-width: 1140px) {
    .bzo-nav-links-container.showHamburger {
      display: none;
    }
    .bzo-nav-links-container {
      display: flex;
    }
  }
  @media screen and (max-width: 415px) {
    .bzo-landing-nav-bar--hamburger {
      margin-right: 0px;
    }
  }
}
