/* Submitted successfully */
.submitted {
  display: flex;
  flex-direction: column;
}

.submitted svg {
  max-width: 380px;
  max-height: 380px;
}

.submitted h2 {
  font-size: 16px;
  margin-top: 25px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 400px;
}

.submitted button {
  width: fit-content;
}
